<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>专家管理</el-breadcrumb-item>
          <el-breadcrumb-item>专家列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tableBox">
          <!-- blue_title -->
          <div class="blueTitle" style="margin-left:12px;">专家列表</div>
          <div class="tableTop">
             <div class="table_l">
                <el-date-picker
                    style="width:220px !import"
                    v-model="seachDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    @change="seachBydate"
                    placeholder="选择查询创建日期">
                </el-date-picker>
                <!-- 地区 -->
                <el-cascader v-model="checkCity"  @change="setCity" :options="district" placeholder="请选择地区" clearable style="width:224px !important;margin-left: 24px;"></el-cascader>
                <!-- 全部 -->
                <el-select v-model="jobTitle" @change="selectResult" style="width: 152px !important;margin-left: 24px;">
                    <el-option
                        v-for="item in resultOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
             </div>
            <div class="table_r">
                <div class="search">
                    <el-input
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        @change="search"
                        v-model="seachcon"
                        class="elSerch"
                        >
                        <template slot="append" style="background-color: #00CCCC;cursor:pointer">搜索</template>
                    </el-input>
                </div>
            </div>
          </div>
           <ExpertListTable ref="ext"/>
      </div>
      <!-- 启用和禁用弹窗 -->
      <el-dialog
        :show-close="false"
        :visible.sync="showProp"
        width="432px"
        center>
       <!-- 内容区 -->
        <div class="showBox">
            <div class="conten">
                <span v-if="!isOpen">是否要禁用该账号？禁用后该账号专家将无法正常登录！</span>
                <span  v-if="isOpen">确认要解除<span style="color:#00cccc">{{docName}}</span>专家的禁用状态？</span>
            </div>
            <div class="sureBotton" @click="closeOpen">确认</div>
        </div>

      </el-dialog>

    </div>
</template>
<script>

import ExpertListTable from '@/components/table/expertListTable.vue'
import { district } from '@/assets/js/cityCode.js'
import {updateStatus} from '@/api/expert.js'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        ExpertListTable,
    },
    data(){
        return{
            district,
            checkCity:null,
            seachDate:null,//日期搜索
            jobTitle:null,//职位
            seachcon:null,
           //预约状态选择
           resultOption:[ {
                            label:'全部',
                            value: null
                        }, {
                            label:'康复师',
                            value: '康复师',
                        },
                        {
                            label:'支具师',
                            value:"支具师",
                        },
                        {
                            label:'其他',
                            value:"其他",
                        }
                        ],
            showProp:false,
            isOpen:false,//是否解除，默认禁用
            linId:null,//列表id
            docName:null,
           
        }
    },
    created(){
        //
    },
  
    methods:{
        //职位选择
        selectResult(e){
            console.log("职位选择",e,this.jobTitle);
        },
        //日期选择
        seachBydate(e){
           console.log("日期选着",e,this.seachDate);
        },
        //地区选着
        setCity(e){
            //console.log("地区选着",e,this.checkCity);
            this.$refs.ext.getData(1,10,null,e[0],e[1]);
        },
        search(e){
            this.$refs.ext.getData(1,10,e);
            //console.log("搜索",e,this.seachcon);
           
        },
        //弹窗开关
        closeOpen(){
            if(this.isOpen){//解除
                updateStatus({
                id:this.linId,
                status:'启用'
            }).then((res)=>{
                  if(res.data.status == 200){
                    this.$message({
                    message: '已解除',
                    type: 'success'
                    });
                    this.showProp = false;
                    this.$refs.ext.getData();
                  }else{
                    this.$message.error(res.data.message);
                  }
                }).catch(res=>{
                   this.$message.error(res);
                })
            }else{
                //禁用
                updateStatus({
                id:this.linId,
                status:'禁用'
            }).then((res)=>{
                  if(res.data.status == 200){
                    this.$message({
                    message: '已禁用',
                    type: 'success'
                    });
                    this.showProp = false;
                    this.$refs.ext.getData();
                  }else{
                    this.$message.error(res.data.message);
                  }
                }).catch(res=>{
                   this.$message.error(res);
                })
            }
        },
        
    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//下拉框icon样式
/deep/.el-input__icon {
    line-height: 2.4 !important;
}
/deep/.el-input__inner{
    border-radius: 4px 0 0 4px !important 
}
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }


//component style
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  align-items: center 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:2px
    background-color: #00CCCC


.seachText
    font-size: 14px
    color: #AEAEAE

//normal style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            align-items: center
            margin-top: 20px
            margin-bottom: 30px
            justify-content: space-between
            .table_l
                display: flex
                align-items: center
            .table_r
                display: flex
                align-items: center
                .search
                    height: 32px
                    margin-left: 16px
                    display: flex
                    .secbox
                        width: 88px
                        height: 32px  
                        background-color: #00cccc
                        text-align: center
                        line-height: 32px
                        font-size: 14px
                        color: #fff
                .delete
                    width: 64px
                    height: 32px
                    background-color: #FC5555
                    text-align: center
                    line-height:32px
                    color: #fff
                    font-size: 14px
                    margin-left: 16px
                    cursor pointer
                    border-radius: 4px
.showBox
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  .conten
    width 270px 
    height: 48px 
    font-size: 16px;
    text-align: center
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #353535;
    line-height: 24px;
  .sureBotton
    width: 120px;
    height: 32px;
    line-height: 32px
    margin-top: 24px
    text-align: center
    background: #00CCCC;
    border-radius: 4px;
    border: 1px solid #00CCCC;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    cursor pointer

            
</style>